<template>
  <div class="support-popover">
    <MListItem @click.stop="openModal('request-call'), $emit('close'), closeSidebar()">
      <template #left-element>
        <ASocial type="phone" />
      </template>
      <template #title>{{ $t("organisms.footer.request-call") }}</template>
    </MListItem>

    <template v-if="contacts?.onlineChatsList">
      <MListItem
        v-for="(item, i) in contacts.onlineChatsList"
        :key="i"
        @click.stop="goToSocial(item.link)"
      >
        <template #left-element>
          <ASocial :type="(item.name.toLowerCase() as SocialType)" />
        </template>
        <template #title>{{ item.name }}</template>
      </MListItem>
    </template>

    <MListItem
      v-if="contacts?.email"
      @click="goToSocial(`mailto:${contacts?.email}`)"
    >
      <template #left-element>
        <ASocial type="mail" />
      </template>
      <template #title>{{ contacts?.email }}</template>
    </MListItem>

    <div class="support-popover__info">
      <ALink
        v-if="contacts?.telephone"
        color="dark"
        :href="supportPhoneHref"
      >
        {{ contacts?.telephone }}
      </ALink>
      <div v-if="contacts?.workingHours" class="support-popover__hint">
        {{ workingDaysText }} {{ contacts?.workingHours?.pb_default?.timeFrom ?? 0 }} - {{ contacts?.workingHours?.pb_default?.timeTo ?? 0 }}
      </div>
      <ALink
        color="grey"
        underline
        :href="getUrl('/pages/contacts.html', false)"
        @click="$emit('close')"
      >
        {{ $t("organisms.footer.contacts") }}
      </ALink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineComponent, computed, onMounted } from 'vue'
import { useLocalization, useModals } from '#imports'
import { useNavigate } from '@/composables/useNavigate'
import { useSupportInfoStore } from '@/stores/marketing'

import type { SocialType } from '@/components/atoms/Social/types'

import ALink from '@/components/atoms/Link/ALink.vue'
import ASocial from '@/components/atoms/Social/ASocial.vue'

import MListItem from '@/components/molecules/ListItem/MListItem.vue'

defineComponent({ name: 'MSupportPopover' })
defineEmits(['close', 'toggle'])

const { getUrl } = useNavigate()
const { openModal, closeSidebar } = useModals()
const { t } = useLocalization()

const { supportInfo: contacts, initializeSupportInfo } = useSupportInfoStore()

const goToSocial = (href: string): void => {
  window.open(href, '_blank')?.focus()
}

const daysOfWeek = [t('molecules.mapShops.days.mon'), t('molecules.mapShops.days.tue'), t('molecules.mapShops.days.wed'), t('molecules.mapShops.days.thu'), t('molecules.mapShops.days.fri'), t('molecules.mapShops.days.sat'), t('molecules.mapShops.days.sun')]

const workingDaysText = computed(() => {
  const nonWorkingDaysList = contacts.value?.workingHours?.nonWorkingDaysList || []

  if (!nonWorkingDaysList.length) {
    return t('pages.contacts.everyday')
  }

  const workingDays = daysOfWeek.filter((_, index) => !nonWorkingDaysList.includes(index + 1))

  if (workingDays.length > 0) {
    const firstWorkingDay = workingDays[0]
    const lastWorkingDay = workingDays[workingDays.length - 1]
    return `${firstWorkingDay} - ${lastWorkingDay}`
  }

  return t('molecules.mapShops.noWorkingDays')
})

const supportPhoneHref = computed(() => contacts.value?.telephone
  ? `tel:${contacts.value.telephone.replace(/(?!^)\D|\s/g, '')}`
  : undefined
)

onMounted(() => {
  if (!contacts.value) {
    initializeSupportInfo().catch(() => undefined)
  }
})
</script>

<style lang="postcss">
.support-popover {
  & .list-item {
    padding: var(--spacer-4xs) var(--spacer-xs) !important;

    &__title {
      font-weight: 400;
    }

    & .social::before {
      height: 16px;
    }
  }

  &__info {
    padding: var(--spacer-base) var(--spacer-xs) 0;

    & > .link:first-child {
      @mixin text-base-bold;
    }
  }

  &__hint {
    margin-bottom: var(--spacer-2xs);
    color: var(--color-text-middle);

    @mixin text-sm;
  }

  @media (--screen-xs) {
    padding: 0;
  }

  @media (--screen-lg) {
    width: 264px;
  }
}
</style>
